import React from 'react'
import {useAuth} from './use-auth'
import {Location, Redirect} from '@reach/router'
import {navigate} from 'gatsby'

const isBrowser = typeof window !== `undefined`

export interface RequireAuthProps {
  children?: React.ReactNode
  redirectTo?: string
}

type WrappedRequireAuthFunc = (props: RequireAuthProps) => JSX.Element

// It is useful to wrap the authentication check in a higher-order component because you can
// separate the stateful logic from the authentication check within other components.
const requireAuth = (ComponentToWrap: React.FC<RequireAuthProps>, notice?: string): WrappedRequireAuthFunc => {
  const WrappedRequireAuth = (props: RequireAuthProps): JSX.Element => {
    const auth = useAuth()

    if (!isBrowser) {
      return <Redirect to="/" noThrow />
    }

    if (auth?.isAuthenticating) {
      return <></>
    }

    return (
      <Location>
        {({location}): JSX.Element => {
          if (!auth?.user) {
            const returnTo = `${location.pathname}${location.search}${location.hash}`
            auth?.setReturnTo(returnTo)
            navigate(props.redirectTo || '/login/', {state: {notice}})
            return <></>
          }
          return <ComponentToWrap {...props} />
        }}
      </Location>
    )
  }
  return WrappedRequireAuth
}

export default requireAuth
