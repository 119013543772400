import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import {API} from '../util/aws'
import requireAuth from '../util/require-auth'

type ProjectArray = Array<{
  projectId: string
}>

const Projects: React.FC = () => {
  const [projects, setProjects] = useState(null as ProjectArray | null)

  useEffect(() => {
    const fetchProjects = async (): Promise<void> => {
      const data = await API.get('api', '/projects', {})

      if (!data || data.error) {
        console.log('API error:', {data})
        throw new Error('Projects API Error')
      }
      const projects = data?.data?.projects?.Items
      setProjects(projects as ProjectArray)
    }
    fetchProjects()
  }, [])

  return (
    <Layout>
      <Head title="Projects" />
      <div>
        <h2>Your projects:</h2>
        {projects?.length === 0 && <p>You have no projects</p>}
        {projects &&
          projects.map(project => {
            return <p key={project.projectId}>Project: {project.projectId}</p>
          })}
      </div>
    </Layout>
  )
}

export default requireAuth(Projects)
